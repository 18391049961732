import styled from "styled-components";

export const StyledContainer = styled.div`
  @-webkit-keyframes slide-in {
    0% { -webkit-transform: translateY(calc(100% + 30px)); }
    100% { -webkit-transform: translateY(0%); }
  }

  @keyframes slide-in {
    0% { transform: translateY(calc(100% + 30px)); }
    100% { transform: translateY(0%); }
  }

  @-webkit-keyframes slide-out {
    0% { -webkit-transform: translateY(0%); }
    100% { -webkit-transform: translateY(calc(100% + 30px)); }
  }

  @keyframes slide-out {
    0% { transform: translateY(0%); }
    100% { transform: translateY(calc(100% + 30px)); }
  }
  
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  right: 0;
  left: 0;
  z-index: 999;
  -webkit-transform: translateY(- calc(100% + 30px));
  transform: translateY(- calc(100% + 30px));

  .slide-in {
    -webkit-animation-name: slide-in;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
    
    animation: slide-in 0.5s forwards;
  }

  .slide-out {
    -webkit-animation-name: slide-out;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
    
    animation: slide-out 0.5s forwards;
  }
`;

export const SlideButton = styled.button`
  @-webkit-keyframes swing-animation {
    0% { -webkit-transform: rotate(0)}
    25% { -webkit-transform: rotate(22.5deg)}
    50% { -webkit-transform: rotate(0)}
    75% { -webkit-transform: rotate(22.5deg)}
    100% { -webkit-transform: rotate(0)}
  }
  
  @keyframes swing-animation {
    0% { transform: rotate(0)}
    25% { transform: rotate(22.5deg)}
    50% { transform: rotate(0)}
    75% { transform: rotate(22.5deg)}
    100% { transform: rotate(0)}
  }
  
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: #f1f3f4;
  outline: none;
  box-shadow: 0 0 4px #0000004f;
  z-index: 9999;
  -webkit-animation-name: swing-animation;
  -webkit-animation-duration: 8s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation: swing-animation 8s infinite linear;
  
  &:hover {
    cursor: pointer;
  }
`;
