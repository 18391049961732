import React, {useState} from 'react';
import {StyledContainer, SlideButton} from "./styled";

import Icon from './../../../static/assets/ico-music.svg';

export function AudioPlayer() {
  const [controlsVisible, setControlsVisible] = useState(true);

  return (
    <>
    <SlideButton
      onClick={() => {
        setControlsVisible((prev) => !prev);
      }}
    >
      <img src={Icon} alt="Music icon"/>
    </SlideButton>
    <StyledContainer>
      <audio
        loop
        preload="none"
        controls
        className={`${controlsVisible ? 'slide-out' : 'slide-in'}`}
      >
        <source src="/audio/soundtrack.mp3" type="audio/mpeg" />
        <source src="/audio/soundtrack.ogg" type="audio/ogg" />
        <p>Your browser doesn't allow to play tha sound.</p>
        <a href="/audio/soundtrack.mp3">Download the exhibition soundtrack</a>
      </audio>
    </StyledContainer>
  </>
  );
}

export default AudioPlayer;
